<template>
  <div class="Factory MERCH">
    <div class="merch-card">
      <div class="search">
        <div class="search-box1">
          <el-input
            v-model="searchApi.userName"
            placeholder="单位名称/编码"
          ></el-input>
        </div>
        <div class="search-box1">
          <el-input
            v-model="searchApi.unitCode"
            placeholder="单位内码/联系人内码"
          ></el-input>
        </div>
        <div class="search-box1">
          <el-select
            v-model="searchApi.userType"
            placeholder="会员类型"
            clearable
          >
            <el-option
              v-for="item in userType"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-box1">
          <el-select
            v-model="searchApi.deactivate"
            placeholder="状态"
            clearable
          >
            <el-option
              v-for="item in stateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-btn" @click="search">查询</div>
      </div>

      <!-- 列表 -->
      <div>
        <div class="table-box">
          <el-table
            :data="usersData.list"
            border
            style="width: 100%"
            height="650"
            :header-cell-style="{ background: '#F2F3F5' }"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="index"
              width="60"
            >
            </el-table-column>
            <el-table-column
              prop="address"
              label="会员编码"
              align="center"
              width="80"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.userId"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.userId }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="单位名称" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.enterprise"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.enterprise }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="简称" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.shopName"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.shopName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="会员类型" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.typeName"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.typeName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="法人姓名"
              align="center"
              width="100"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.legalEntity"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.legalEntity }}</div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column prop="address" label="证件号码" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.serialNumber"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.serialNumber }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="单位内码" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.erpCode"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.erpCode }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="联系人内码" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.contactsCode"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.contactsCode }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="金融产品"
              align="center"
              width="100"
            >
              <template slot-scope="{ row }" v-if="row.payType == 50">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="药聚力白条"
                  placement="bottom"
                >
                  <div class="exceed">药聚力白条</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="switch"
              label="支付权限"
              align="center"
              width="80"
            >
              <template slot-scope="{ row }">
                <div v-if="row.deactivate == 1" class="deactivate">关闭</div>
                <div v-if="row.deactivate == 0" class="deactivate1">开启</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="200"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!--  -->
                  <div @click="openAdd(row)">贷款记录</div>
                  <div
                    @click="openJurisdiction(row)"
                    v-if="row.deactivate == 0"
                  >
                    关闭支付权限
                  </div>
                  <div
                    @click="openJurisdiction(row)"
                    v-if="row.deactivate == 1"
                  >
                    开启支付权限
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <div class="pages">
          共<span>{{ usersData.totalPage }}</span
          >页/<span>{{ usersData.totalRecords }}</span> 条数据
        </div>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="usersData.per_page"
          layout=" prev, pager, next, jumper"
          :total="usersData.totalRecords"
          :current-page="searchApi.currPage"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <span v-if="dialogMess.deactivate==0">确认要关闭会员【{{dialogMess.enterprise}}】的药聚力白条支付权限？关闭后该会员在药聚力小程序中药聚力白条支付方式不可见。</span>
      <span v-if="dialogMess.deactivate==1">确定要开启会员【{{dialogMess.enterprise}}】的药聚力白条支付权限？开启后该会员在药聚力小程序中可以使用药聚力白条支付。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="switchChange" v-if="dialogMess.deactivate==0"
          >关 闭</el-button
        >
        <el-button type="primary" @click="switchChange" v-if="dialogMess.deactivate==1"
          >开 启</el-button
        >
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { search } from "babel-standalone";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("userLoan"); //vuex公共库
export default {
  data() {
    return {
      stateOption: [
        {
          value: "0",
          label: "开启",
        },
        {
          value: "1",
          label: "关闭",
        },
      ],
      searchApi: {
        currPage: 1,
        deactivate: "",
        userName: "",
        unitCode: "",
        userType: "",
      },
      usersData: {},
      loading: true,
      AddData: {
        sort: 0,
      },

      islogistics: false,
      dialogVisible: false,
      dialogTitle: "",
      dialogMess:{},
      // isopen:true,
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
      "userType",
    ]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchApi = this.detailsSeach[router];
    }
    this.toSearch();
  },
  mounted() {
    this.tableHei = this.mainHright * 1 + 50;
  },
  methods: {
    ...mapActions(["postPinganLoanssSelectPinganLoans",'postPinganLoanssUpdatePinganLoans']),
    ...commonIndex.mapMutations(["changeSeach"]),
    openJurisdiction(val) {
      if(val.deactivate==0){
        this.dialogTitle='关闭会员支付权限'
      }else if(val.deactivate==1){
        this.dialogTitle='开启会员支付权限'
      }
      this.dialogMess=val
      this.dialogVisible = true;
    },
    // 贷款记录
    openAdd(val) {
      this.$router.push({
        path: "/memberLoanRecord",
        query: {
          userId: val.userId,
        },
      });
    },
    // 改变平安代开通状态
    async switchChange() {
      let deactivate=null
      if(this.dialogMess.deactivate==0){
        deactivate=1
      }else if(this.dialogMess.deactivate==1){
        deactivate=0
      }
      let res=await this.postPinganLoanssUpdatePinganLoans({userId:this.dialogMess.userId,deactivate})
      if(res.code=='000000'){
        this.$message({
          message: res.message,
          type: 'success'
        });
        this.dialogVisible=false
        this.toSearch();
      }else{
        this.$message({
          message: res.message,
          type: 'error'
        });
      }
      // console.log(val);
      // this.loading = true;
      // this.toSearch();

    },
    handleCurrentChange(e) {
      this.searchApi.currPage = e;
      this.toSearch();
    },
    search() {
      this.searchApi.currPage = 1;
      this.toSearch();
    },
    async toSearch() {
      let data = await this.postPinganLoanssSelectPinganLoans(this.searchApi);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchApi };
      save.router = router;
      this.changeSeach(save);
      console.log(data.content);
      data.content.list.forEach((item, index) => {
        item.userId = item.userId + "";
        item.index =
          (data.content.pageNumber - 1) * data.content.pageSize + index + 1;
      });
      this.usersData = data.content;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .search {
      display: flex;
      padding-top: 13px;
      .search-box1 {
        width: 250px;
        margin-right: 20px;
      }
      .search-btn {
        width: 80px;
        height: 40px;
        line-height: 40px;
        background-color: #06b7ae;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
      .pages {
        line-height: 32px;
        font-size: 14px;
        color: #31373d;
        span {
          color: #06b7ae;
        }
      }
    }
  }
}
::v-deep .el-select {
  width: 100%;
}
.table-box {
  margin-top: 20px;
}
.deactivate {
  color: #f34444;
}
.deactivate1 {
  color: #666666;
}
::v-deep .el-dialog__title{
  font-weight: bold;
  font-size:16px;
  color: #1D2129;
}
::v-deep  .el-dialog__body{
  padding: 10px 20px;
  line-height: 24px;
  color: #1D2129;
  font-size: 14px;
}
</style>
