var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Factory MERCH"},[_c('div',{staticClass:"merch-card"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"search-box1"},[_c('el-input',{attrs:{"placeholder":"单位名称/编码"},model:{value:(_vm.searchApi.userName),callback:function ($$v) {_vm.$set(_vm.searchApi, "userName", $$v)},expression:"searchApi.userName"}})],1),_c('div',{staticClass:"search-box1"},[_c('el-input',{attrs:{"placeholder":"单位内码/联系人内码"},model:{value:(_vm.searchApi.unitCode),callback:function ($$v) {_vm.$set(_vm.searchApi, "unitCode", $$v)},expression:"searchApi.unitCode"}})],1),_c('div',{staticClass:"search-box1"},[_c('el-select',{attrs:{"placeholder":"会员类型","clearable":""},model:{value:(_vm.searchApi.userType),callback:function ($$v) {_vm.$set(_vm.searchApi, "userType", $$v)},expression:"searchApi.userType"}},_vm._l((_vm.userType),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.type_name,"value":item.id}})}),1)],1),_c('div',{staticClass:"search-box1"},[_c('el-select',{attrs:{"placeholder":"状态","clearable":""},model:{value:(_vm.searchApi.deactivate),callback:function ($$v) {_vm.$set(_vm.searchApi, "deactivate", $$v)},expression:"searchApi.deactivate"}},_vm._l((_vm.stateOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"search-btn",on:{"click":_vm.search}},[_vm._v("查询")])]),_c('div',[_c('div',{staticClass:"table-box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.usersData.list,"border":"","height":"650","header-cell-style":{ background: '#F2F3F5' }}},[_c('el-table-column',{attrs:{"label":"序号","align":"center","prop":"index","width":"60"}}),_c('el-table-column',{attrs:{"prop":"address","label":"会员编码","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.userId,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.userId))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"单位名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.enterprise,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.enterprise))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"简称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.shopName,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.shopName))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"会员类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.typeName,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.typeName))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"法人姓名","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.legalEntity,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.legalEntity))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"证件号码","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.serialNumber,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.serialNumber))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"单位内码","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.erpCode,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.erpCode))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"联系人内码","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.contactsCode,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.contactsCode))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"金融产品","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.payType == 50)?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"药聚力白条","placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v("药聚力白条")])])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"switch","label":"支付权限","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.deactivate == 1)?_c('div',{staticClass:"deactivate"},[_vm._v("关闭")]):_vm._e(),(row.deactivate == 0)?_c('div',{staticClass:"deactivate1"},[_vm._v("开启")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"userBtn"},[_c('div',{on:{"click":function($event){return _vm.openAdd(row)}}},[_vm._v("贷款记录")]),(row.deactivate == 0)?_c('div',{on:{"click":function($event){return _vm.openJurisdiction(row)}}},[_vm._v(" 关闭支付权限 ")]):_vm._e(),(row.deactivate == 1)?_c('div',{on:{"click":function($event){return _vm.openJurisdiction(row)}}},[_vm._v(" 开启支付权限 ")]):_vm._e()])]}}])})],1)],1)]),_c('div',{staticClass:"merch-page"},[_c('div',{staticClass:"pages"},[_vm._v(" 共"),_c('span',[_vm._v(_vm._s(_vm.usersData.totalPage))]),_vm._v("页/"),_c('span',[_vm._v(_vm._s(_vm.usersData.totalRecords))]),_vm._v(" 条数据 ")]),_c('el-pagination',{attrs:{"background":"","page-size":_vm.usersData.per_page,"layout":" prev, pager, next, jumper","total":_vm.usersData.totalRecords,"current-page":_vm.searchApi.currPage},on:{"current-change":_vm.handleCurrentChange}})],1)]),_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.dialogVisible,"width":"400px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.dialogMess.deactivate==0)?_c('span',[_vm._v("确认要关闭会员【"+_vm._s(_vm.dialogMess.enterprise)+"】的药聚力白条支付权限？关闭后该会员在药聚力小程序中药聚力白条支付方式不可见。")]):_vm._e(),(_vm.dialogMess.deactivate==1)?_c('span',[_vm._v("确定要开启会员【"+_vm._s(_vm.dialogMess.enterprise)+"】的药聚力白条支付权限？开启后该会员在药聚力小程序中可以使用药聚力白条支付。")]):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.dialogMess.deactivate==0)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.switchChange}},[_vm._v("关 闭")]):_vm._e(),(_vm.dialogMess.deactivate==1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.switchChange}},[_vm._v("开 启")]):_vm._e(),_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }